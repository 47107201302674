import React, { useRef, useState } from 'react';
import FaIcon from './FaIcon';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const ReferenceItem = ({ item, anim, delay }) => {
    const [ modal, setModal ] = useState(false);
    const imgRef = useRef();

    const formatT = (t) => {
        let css = "";
        switch(t){
            case "CodeIgniter 3": 
            default:
                css = "text-bg-info"; break;
            case "WordPress":
                css = "text-bg-danger"; break;
            case "Elementor":
                css = "text-bg-warning"; break;
            case "Bootstrap":
            case "React.js":
                css = "text-bg-success"; break;
        };
        return css;
    }

    const handleModal = () => setModal(!modal);
    const updateImage = (el) => {
        imgRef.current.src = el.target.src;
    }

    return (
        <div className="col-md-3 mb-3" data-aos={anim} data-aos-delay={delay}>
            <div className="card">
                <img src={`./images/references/${item.thumb}`} style={{height:"200px"}} alt="reference" className="card-img-top" />
                <div className="card-body">
                    <h5 className="card-title text-center">{item.name}</h5>
                    <p className="card-text text-center">
                        {(item.tech.map((t) => {
                            return <span className={`badge ${formatT(t)}`} style={{marginLeft:"2px",marginRight:"2px"}}>
                                {t}
                            </span>
                        }))}
                    </p>
                    <p className="card-text text-center">
                        {(item.siteUrl) && (<a href={item.siteUrl} rel="noreferrer" target="_blank" style={{marginLeft:"2px",marginRight:"2px"}}><FaIcon type="light" icon="globe" size="2x" /></a>)}
                        {(item.gitUrl) && (<a href={item.gitUrl} rel="noreferrer" target="_blank" style={{marginLeft:"2px",marginRight:"2px"}}><FaIcon type="brands" icon="github" size="2x"/></a>)}
                        <Link to="#" onClick={(e) => { e.preventDefault(); handleModal(); }} style={{marginLeft:"2px",marginRight:"2px"}}><FaIcon type="light" icon="info-circle" size="2x"/></Link>
                    </p>
                </div>
            </div>
            <Modal show={modal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Button onClick={handleModal} style={{position:"absolute",top:"0px",right:"0px",borderTopLeftRadius:0,borderBottomLeftRadius:0,borderBottomRightRadius:0}}><FaIcon type="light" icon="times" /></Button>
                    <h3 className='segment-title'>{item.name}</h3>
                    <img ref={(el) => imgRef.current = el} src={`./images/references/${item.thumb}`} alt="reference" className="card-img-top" />
                    {(item?.gallery?.length > 0) && (item?.gallery?.map((img) => (
                        <img onClick={updateImage} src={`./images/references/${img}`} alt="gallery" style={{width:'64px',height:'64px'}} />
                    )))}<br/>
                    <br/>
                    <b>Technológiák: </b> {(item.tech.map((t) => { return <span className={`badge ${formatT(t)}`} style={{marginLeft:"2px",marginRight:"2px"}}> {t} </span> }))}
                    {(item.description) && (<p><b>Leírás:</b> {item.description}</p>)}
                </Modal.Body>
            </Modal>
        </div>
    );
};



export default ReferenceItem;