import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReferenceItem from '../Widgets/ReferenceItem';

const References = () => {
    const [ items, setItems] = useState([]);

    useEffect(() => {
        setItems([]);
        const fetchItems = async () => {
            const _items = await axios({url: "/datas/references.json", method: "GET"});
            setItems(_items.data)
        }; fetchItems();
    },[]);

    return (
        <section className="page" id="portfolio">
            <div className="container-fluid about-box page-box">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="segment-title text-center" data-aos="fade-up">Néhány munkám</h3>
                        <div className="row">
                           {(items.length > 0) && (items?.sort((a,b) => a.order - b.order).map((item, index) => (
                            <ReferenceItem item={item} anim="fade-up" delay={50 * index}/> 
                            )))}  
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default References;