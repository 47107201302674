import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaIcon from '../Widgets/FaIcon';

const Hero = () => {
    return (
        <section className="page">
            <div className="jumbotron">
                <div className="jumbotron-text p-5 text-center rounded-3"  data-aos="fade-up" data-aos-offset="0">
                    <h1 className="jumbotron-title" data-aos="fade-up" data-aos-delay="100">Tajti Zoltán</h1>
                    <p data-aos="fade-up" data-aos-delay="200">
                        <a href="https://www.linkedin.com/in/zoltan-tajti" rel="noreferrer" target="_blank"><FaIcon type="brands" icon="linkedin" size="2x" /></a>
                        <a href="https://www.facebook.com/soltantajti" rel="noreferrer" target="_blank" style={{marginLeft:"5px"}}><FaIcon type="brands" icon="facebook-square" size="2x" /></a>
                        <a href="https://github.com/zoltantajti" rel="noreferrer" target="_blank" style={{marginLeft:"5px"}}><FaIcon type="brands" icon="github-square" size="2x" /></a>
                    </p>
                    <div>
                        <ButtonGroup data-aos="fade-up" data-aos-delay="300">
                            <Button onClick={(e) => e.preventDefault()} rel='noreferer' variant="outline-primary" className="mb-3">
                                Önéletrajz <FaIcon type="solid" icon="download" />
                            </Button>
                            <Button as={Link} to="#contact" variant='outline-secondary' className="mb-3">
                                Kapcsolat
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className="jumbotron-footer">
                    <a href="#about" className="btn-circle-secondary">
                        <i className="fa-solid fa-angle-down"></i>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Hero;