import axios from 'axios';
import React, { useEffect, useState } from 'react';
import EduItem from '../Widgets/EduItem';
import SkillItem from '../Widgets/SkillItem';

const Education = () => {
    const [ edu, setEdu ] = useState([]);
    const [ works, setWorks ] = useState([]);
    const [ skills, setSkills ] = useState([]);

    useEffect(() => {
        const setItems = async () => {
            setEdu([]);
            setWorks([]);
            setSkills([]);

            const _edu = await axios({url: "/datas/education.json",method: "GET"});
            setEdu(_edu.data);

            const _works = await axios({url: "/datas/works.json", method: "GET"});
            setWorks(_works.data);

            const _skills = await axios({url: "/datas/skills.json", method: "GET"});
            setSkills(_skills.data);

        }; setItems();
        
    },[]);


    return (
        <section className="page" id="resume">
            <div className="container-fluid resume-box page-box">
                <div className="container">
                    <div className="row mb-5" data-aos="fade-up">
                        <div className="col-md-3">
                            <div className="education">Tanulmányok</div>
                        </div>
                        <div className="col-md-9">
                            {(edu.sort((a,b) => a.from - b.from).map((item) => ( <EduItem item={item} /> )))}
                        </div>
                    </div>

                    <div className="row mb-5" data-aos="fade-up" data-aos-delay="200">
                        <div className="col-md-3">
                            <div className="education">MUNKAHELYEK</div>
                            <i>csak releváns</i>
                        </div>
                        <div className="col-md-9">
                            {(works.sort((a,b) => a.from - b.from).map((item) => ( <EduItem item={item} /> )))}
                        </div>
                    </div>

                    <div className="row" data-aos="fade-up" data-aos-delay="200">
                        <div className="col-md-3">
                            <div className="education">KÉSZSÉGEK</div>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-6 col-xs-12 mb-5">
                                    <div className="edu-title text-center">Beszélt nyelvek</div>
                                    {(skills.filter((skill) => { return skill.type === "language"}).sort((a,b) => b.level - a.level).map((skill) => (
                                        <SkillItem skill={skill} />
                                    )))}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-5">
                                    <div className="edu-title text-center">Frontend</div>
                                    {(skills.filter((skill) => { return skill.type === "frontend"}).sort((a,b) => b.level - a.level).map((skill) => (
                                        <SkillItem skill={skill} />
                                    )))}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-5">
                                    <div className="edu-title text-center">Backend</div>
                                    {(skills.filter((skill) => { return skill.type === "backend"}).sort((a,b) => b.level - a.level).map((skill) => (
                                        <SkillItem skill={skill} />
                                    )))}
                                </div>
                                <div className="col-md-6 col-xs-12 mb-5">
                                    <div className="edu-title text-center">Mobil</div>
                                    {(skills.filter((skill) => { return skill.type === "mobil"}).sort((a,b) => b.level - a.level).map((skill) => (
                                        <SkillItem skill={skill} />
                                    )))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Education;