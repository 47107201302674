import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { A11y, Navigation, Scrollbar, Pagination, Autoplay } from 'swiper/modules';

const Testimonials = () => {
    return (
        <section class="page" id="testimonials">
            <div class="container-fluid about-box page-box">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="segment-title text-center">Ajánlások</h3>
                        <Swiper modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} spaceBetween={10} slidesPerView={1} autoplay={true} scrollbar={{ draggable: true }}>
                            <SwiperSlide>
                                <span style={{ textAlign: "left" }}>
                                    <i>Gyors, megbízható és könnyen tanul.</i><br /><br />
                                    -- Fónagy I. | Web Management Kft.
                                </span>
                            </SwiperSlide>
                            <SwiperSlide>
                                <span style={{ textAlign: "left" }}>
                                    <i>Mindent meg tudott csinálni, amit kértem tőle.</i><br /><br />
                                    -- Erdős L. | Erdős Skin & Design
                                </span>
                            </SwiperSlide>
                            <SwiperSlide>
                                <span style={{ textAlign: "left" }}>
                                    <i>Van érzéke a weboldalak kinézetéhez! Csak ajánlani tudom!</i><br/><br/>
                                    -- Fónagy T. | Villa Tópart
                                </span>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;