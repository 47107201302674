import "./App.scss";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import Hero from "./Segments/Hero";
import { BrowserRouter } from "react-router-dom";
import About from "./Segments/About";
import Education from "./Segments/Education";
import References from "./Segments/References";
import Testimonials from "./Segments/Testimonials";
import Contact from "./Segments/Contact";


function App() {

  useEffect(() => {
    AOS.init();
  },[]);

  return (
    <BrowserRouter>
      <Hero />
      <About />
      <Education />
      <References />
      <Testimonials />
      <Contact />
    </BrowserRouter>
  );
}

export default App;
