import React from 'react';

const About = () => {
    return (
        <section className="page" id="about">
            <div className="container-fluid about-box page-box">
                <div className="row align-items-center">
                    <div className="col-md-3" data-aos="fade-up">
                        <img src="./images/photo.png" alt="Portrait" className="img-responsive profile-photo" />
                    </div>
                    <div className="col-md-9" data-aos="fade-up">
                        <h3 className="segment-title">Rólam</h3>
                        <p className="about-text">
                            Tajti Zoltán vagyok, Webfejlesztő. 1992-ben születtem, és a weboldalak, webes alkalmazások már középiskolában érdekeltek. 
                            Szerettem és szeretem tudni, mi, hogyan működik, ezért nem vagyok rest utánajárni dolgoknak.<br/>
                            <br/>
                            A programozás alapjait középiskolában tanultam, bár nem ezen irányban tevékenykedtem sokáig, de most szeretnék visszatérni és 
                            fejlődni a témában. Jelen webhelynek is az a célja, hogy szeretnék egy olyan céget találni, ahol kamatoztathatom tudásom, valamint 
                            új ismeretekre tehetek szert.
                        </p>
                        <div className="row about-text lh-2">
                            <div className="col-md-6">
                                <b><i className="fa-solid fa-city"></i> Lakcím:</b> Pásztó<br/>
                                <b><i className="fa-regular fa-flag"></i> Állampolgárság:</b> Magyar<br/>
                                <b><i className="fa-solid fa-graduation-cap"></i> Iskola:</b> Stromfeld Aurél, Salgótarján
                            </div>
                            <div className="col-md-6">
                                <b><i className="fa-solid fa-calendar-alt"></i> Életkor:</b> 32<br/>
                                <b><i className="fa-regular fa-face-grin-stars"></i> Érdeklődés:</b> Autósport, Repülés, Rádiózás<br/>
                                <b><i className="fa-solid fa-building-user"></i> Munkahely:</b> Vodafone Magyarország
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;