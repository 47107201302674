import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import FaIcon from '../Widgets/FaIcon';



const ContacForm = ({ googleReCaptchaProps }) => {
    const [fields, setFields] = useState({ name: "", email: "", message: "" });
    const [message, setMessage] = useState({ have: false, css: "", text: ""});
    const [token, setToken] = useState(null);
    const getToken = useCallback(async () => { const _token = await googleReCaptchaProps.executeRecaptcha(); setToken(_token); }, [googleReCaptchaProps]);
    const handleChange = (event) => { const { name, value } = event.target; setFields((prev) => ({ ...prev, [name]: value })); }
    const onSubmit = async (event) => {
        event.preventDefault();
        const _token = await googleReCaptchaProps.executeRecaptcha();
        if(_token) {
            axios.post("https://tajtizoltan.hu/vendor/sendMessage.php", {
                token: _token,
                name: fields.name,
                email: fields.email,
                message: fields.message
            }).then((response) => {
                setMessage({have: true, css: "success", text: "Az üzenetet megkaptam! Hamarosan válaszolni fogok!"});
                setFields({name: "", email: "", message: ""});
                console.log(response);
            }).catch((error) => {
                setMessage({have: true, css: "danger", text: "Hiba történt! Kérem, próbálja meg később!"});
                console.log(error);
            });
        };
    };
    return (
        <section className="page" id="contact">
            <div className="container-fluid contact-box page-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="segment-title text-center mb-5">Kapcsolat</h3>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    {(message.have) && (<Alert variant={message.css}>{message.text}</Alert>)}
                                    <Form onSubmit={onSubmit}>
                                        <FloatingLabel controlId="floatingInput" label="Név" className="mb-3" >
                                            <Form.Control type="text" name="name" value={fields.name} onChange={handleChange} placeholder="Név" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="floatingInput" label="E-mail cím" className="mb-3" >
                                            <Form.Control type="email" name="email" value={fields.email} onChange={handleChange} placeholder="E-mail cím" />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="floatingInput" label="Üzenet" className="mb-3" >
                                            <textarea className='form-control' name="message" style={{ height: "100px" }} value={fields.message} onChange={handleChange} placeholder="Üzenet"></textarea>
                                        </FloatingLabel>
                                        <Button type="submit" variant="outline-primary">Küldés</Button>
                                    </Form>
                                </div>
                                <div className="col-md-6 ftr">
                                    <p style={{textAlign:"justify"}}>
                                        Az Elküldés gombra kattintva hozzájárul, hogy a weboldal, e-mailben továbbítsa az üzenetét felém, és az e-mail kiszolgáló, szerverén tárolja az Ön által megadott nevet, e-mail címet és üzenetet.
                                    </p>
                                    <hr/>
                                    <h5 class="segment-title">Tajti Zoltán</h5>
                                    <FaIcon type="solid" icon="home" /> 3065, Pásztó, Vár utca 117&nbsp;
                                    <a href="mailto:info@tajtizoltan.hu"><i className="fa fa-fw fa-envelope"></i> info@tajtizoltan.hu</a><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const ContacFromWithReCaptcha = withGoogleReCaptcha(ContacForm);

const Contact = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey='6LcL78QpAAAAAM9Mss9B81ioFvBN5g474hECNenB'>
            <ContacFromWithReCaptcha />
        </GoogleReCaptchaProvider>
    )
};
export default Contact;