import React, { useEffect } from 'react';

const EduItem = ({ item }) => {
    return (
        <div className="edu-record mb-5">
            <div className="edu-title">{item.name}</div>
            <div className="edu-meta mb-3">
                <i>{item.type}</i>
                <span>•</span>
                {item.from} - {item.to}
            </div>
            {(item?.text) && (<div className="edu-text"> {item.text} </div>)}
        </div>
    );
};


export default EduItem;