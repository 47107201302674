import React from 'react';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import FaIcon from './FaIcon';

const SkillItem = ({ skill }) => {

    const renderTooltip = (props) => (<Tooltip id="skill-tooltip" {...props}>{skill?.text}</Tooltip>);

    if(skill?.text) return (
        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={ renderTooltip }>
            <ProgressBar now={skill.level} label={<span style={{cursor:'pointer'}}> {skill.name} <FaIcon type="light" icon="question-circle" /> </span>} variant="custom" className='mb-2'/>
        </OverlayTrigger>
    );

    return (
        <ProgressBar now={skill.level} label={<span> {skill.name} </span>} variant="custom" className='mb-2'/>
    );
};

export default SkillItem;